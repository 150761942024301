<template>
  <base-section
    id="post"
    space="56"
  >
    <v-container>
      <v-row>
        <div v-if="!current_post || !current_post.fields || current_post.fields.lenght <= 0">
            
            <v-alert
              border="left"
              colored-border
              elevation="2"
              type="error"
              >
              Pas de formulaire disponible pour ce sujet.
          </v-alert>
         
          </div>
        <v-col v-else>
          <v-alert
          border="top"
          colored-border
          elevation="2"
          v-if="current_post.title"
          >
            <p class="text-h5" style=" font-size: 1.2em !important; letter-spacing: 0">{{ current_post.title }}</p>
            <p class="text-body-2" style=" font-size: 1em !important; " v-html="current_post.description.content"></p>
          </v-alert>
          <v-alert
            color="#ffab00"
            elevation="2"
            colored-border
            border="left"
            type="warning"
            v-if="current_post.eligibility"
          >
            {{ current_post.eligibility }}
          </v-alert>
          <v-alert v-if="current_post.nb"
              border="left"
              colored-border
              elevation="2"
              type="info"
              >
              <div v-if="current_post.nb && current_post.nb.type == 'html'" v-html="current_post.nb.content"></div>
              <p v-else>{{ current_post.nb }}</p>
              
          </v-alert>
          <!-- <p>Vous souhaitez solliciter le soutien du Fonds, remplissez le formulaire ci-dessous: 
          </p> -->
          <v-snackbar
            v-model="snackbar"
            :centered="true"
          >
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
        <v-expand-transition>
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation
            method="post"
            :disabled="disableForm"
            v-show="!formComplete"
          >
          <input type="hidden" :value="current_post._id" name="formid">
          <div v-for="(field, idx) in current_post.fields" :key="idx">
            <div v-if="field.type == 'String'">
              <v-text-field
                v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
                v-model="formData[field.name]"
                v-bind="field"
                required
              >
              </v-text-field>
            </div>
            <div v-if="field.type == 'textarea'">
              <v-textarea
                v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
                  v-model="formData[field.name]"
                  v-bind="field"
          
        ></v-textarea>
            </div>
          <div v-if="field.type == 'radio'">
            <v-radio-group
              v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
              v-model="formData[field.name]"
              :row="field.orientation == 'row'"
              v-bind="field"
            >
              <v-radio
                  v-for="o in field.options"
                  :key="o.name"
                  :label="o.label"
                  :value="o.name"
              ></v-radio>
            </v-radio-group>
          </div>        
          <div v-if="field.type == 'checkbox'">
            <v-checkbox
              v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)"
              v-model="formData[field.name]"
              v-bind="field"
              :required="field.required|| false"
            ></v-checkbox>
          </div>

          <div v-if="field.type == 'file'">
            <div v-if="(field.displayIf ? formData[field.displayIf.fieldData] == field.displayIf.value : true)" >
              <!-- <p>Ajouter des documents justificatifs <strong>{{ field['max-files'] ? '(' + field['max-files'] + ' documents maximum )': '' }} </strong> </p> -->
              <p v-if="field.intro"> {{ field.intro }}</p>
              <file-pond
                :disabled="disableForm"
                :init="handleFilePondInit"
                credits=""
                v-bind="field"
                v-model="formData[field.name]"
                :files="formData[field.name]"
                >
            </file-pond>
            </div>
          </div>
           <div v-if="field.type == 'html'">
            <p v-html="field.content"></p>
           </div> 
            
          </div>
          
            <base-btn
              :disabled="!valid || disableForm || (current_post.active == false)"
              color="primary"
              class="mr-4"
              @click="sendFormData"
              v-if="!snackbar"
            >
              Soumettre
            </base-btn>
          </v-form>
        </v-expand-transition>
          
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import axios from "axios";
const FilePond = vueFilePond();
  export default {
    name: 'SectionPost',
    components: {
        FilePond,
      },
    data () {
      return {
        disableForm: false,
        message: '',
        snackbar : false,
        mypost: null,
        // posts: [
        //   {
        //     id: 1,
        //     serverUrl: process.env.VUE_APP_POST_URL,
        //     title: 'Soutien financier pour une enquête journalistique contribuant à un débat d’intérêt général.',
        //     description: {
        //       type: 'html', 
        //       content : `<p>Le FIP apporte son financement à des d’enquêtes journalistiques contribuant à un débat d’intérêt général, publiées sous forme de livres (papier ou numérique). </p>
        //       <p>Ce financement prendra la forme d’un à-valoir remboursable en cas de succès.</p>
        //       <p>Les informations transmises par les candidats pour justifier de l’intérêt de leur enquête sont strictement confidentielles et ont pour seul but d’évaluer leur projet. Les membres du conseil d’administration s’engagent à conserver ces informations confidentielles à ne pas les partager en dehors du conseil. </p>
        //       <p> <strong>Qui sont les candidats éligibles ?</strong></p>
        //       <p>Le financement est réservé aux journalistes professionnels indépendants. Il ne peut concerner un journaliste salarié d’un média dont le Fonds serait directement ou indirectement actionnaire.
        //           Chaque porteur de projet ne peut soumettre qu’une seule candidature par appel à projets. 
        //           </p>
        //       <p>Un journaliste dont le projet d’enquête n’a pas été retenu par le FIP peut en déposer un nouveau lors d’un appel à projet ultérieur. Il est également possible pour un journaliste ayant déjà fait l’objet d’un soutien financier pour une enquête de déposer un nouveau projet d’enquête. Le FIP se réserve cependant le droit de privilégier des projets d’enquêtes journalistiques n’ayant pas encore obtenu le soutien du FIP.</p>
        //       <p><strong>Quand présenter un projet ? </strong></p>    
        //       <p>La date limite de dépôt des candidatures est fixée au [à compléter] Les projets retenus seront annoncés au plus tard le [à compléter]. Toutefois, en fonction du nombre de dossiers reçus, le délai d’instruction pourra être allongé. 
        //           Les candidats présélectionnés sont auditionnés par le conseil d’administration avant la décision finale.</p>
        //       <p>Quel est le montant de financement apporté par le Fonds? </p>
        //       <p><strong>
        //           Le FIP dispose chaque année d’un budget d’environ 200.000 euros qu’il répartit librement entre les  quatre missions qu’il s’est assigné. Le conseil d’administration a seule compétence pour décider à quels projets il accorde ou non un soutien, ainsi que le montant de celui-çi.
        //       </strong></p>
        //       <p>Le financement peut intervenir en complément d’autres modes de financement du projet (bourses, prêts bancaires…)</p>
        //       <p>Aucune dépense engagée avant le déblocage des sommes par le FIP ne pourra être couverte par le financement. </p>
        //       <p>Le porteur de projet candidate en remplissant ce formulaire en ligne. Libre à lui de fournir jusqu’à cinq documents supplémentaires qu’il estime utile pour enrichir la compréhension du projet, sa nécessité, et son caractère d’intérêt général.</p>
        //       `,
        //     },
        //     eligibility: 'Soutien réservé aux journalistes professionnels indépendants',
        //     // nb: 'NB: Le fonds dispose chaque année d’un budget d’environ 200.000€ qu’il répartit entre ses différentes missions et les sollicitations qui lui sont soumises. Le Conseil d’administration a seul compétence pour décider s’il accorde ou non un soutien, ainsi que le cas échéant, le montant de celui-ci.',
        //     fields: {
        //       lastName: {
        //         name: 'lastname',
        //         label: 'Nom',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         rules:[
        //               v => !!v || 'Nom est obligatoire',
        //               v => (v? v.length <= 10 : '') || 'Max 10 caractères',
        //             ]
        //         },
        //       firstName: {
        //         name: 'firstName',
        //         label: 'Prénom',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         },
        //       address: {
        //         name: 'address',
        //         label: 'Adresse',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         },
        //       email: {
        //         name: 'email',
        //         label: 'Email',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         rules: [
        //                 v => !!v || 'E-mail is required',
        //                 v => /.+@.+/.test(v) || 'E-mail must be valid',
        //               ]
        //         },
        //       support: {
        //         name: 'support',
        //         label: 'Quels sont le ou les médias auxquels vous collaborez régulièrement',
        //         // description: 'Pourquoi ce projet ? Pourquoi maintenant ? A quel(s) problème(s) ou quel(s) besoin(s) ce projet répond-il ? partenariats et modes de diffusion ?',
        //         type: 'String',
        //         counter: 255,
        //         multiline: ''
        //       },
        //       autre : {
        //         label: 'Description du projet d’enquête en 3.000 signes (Pourquoi ce projet? A quelle urgence répond-il ? En quoi revêt-il un caractère d’intérêt général?)',
        //         type: 'textarea',
        //         lenght: 3000,
        //         counter: 3000,
        //         name: 'autre'
        //       },
        //       montant: {
        //         name: "montant",
        //         label: 'Précisez le montant du soutien sollicité et, s’il est accordé, le type de dépenses qu’il financera',
        //         type: 'String',
        //         counter: 255,
        //         multiline: ''
        //       },
        //       benef: {
        //         name: "benef",
        //         label: 'Bénéficiez-vous d’une autre source de financement ou avez-vous sollicité une autre source de financement',
        //         type: 'String',
        //         counter: 255,
        //       },
        //       fichier: {
        //         label: 'Justificatifs',
        //         type: 'file',
        //         multi: true,
        //         name: 'files',
        //         'max-files':5,
        //         ref:"pond",
        //         'label-idle':"Glisser-déplacer ou cliquer pour téléchager des documents",
        //         'allow-multiple': "true",
        //         'accepted-file-types': "image/jpeg, image/png, application/pdf",
        //         // server: "http://localhost:8383/api",
        //         server: process.env.APP_VUE_FILEMANAGE_URL,
        //         instantUpload: "true",
        //         labelFileProcessing: "Téléchargement...",
        //         labelFileProcessingComplete: "Téléchargement terminé",
        //         labelTapToCancel: "Cliquer pour annuler",
        //         labelTapToRetry: "Cliquer pour réessayer",
        //         labelTapToUndo: "Cliquer pour annuler",
        //       },
        //       rgpd: {
        //         type: 'html',
        //         content: `En remplissant ce formulaire, vous consentez à ce que le Fonds pour l’Indépendance de la Presse, responsable de traitement, utilise les données personnelles vous concernant pour le traitement de votre demande de financement. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de modification, de portabilité, de suppression et d’opposition au traitement des informations vous concernant. Vous pouvez exercer ces droits via ce formulaire de contact <a href='/contact-us'> ici </a>. Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr).`,
        //       },
        //       cgu: {
        //         type: 'checkbox',
        //         label: `Accepter la politique de traitement des données personnelles`,
        //         required: true,
        //         name: "cgu",
        //         rules : [v => !!v || 'CGU obligatoire',]
        //       },
        //     }
        //   },
        //   {
        //     id: 2,
        //     title: 'Vous sollicitez un soutien financier pour des travaux de recherche scientifique dédiés à la lutte contre la désinformation',
        //     description: {
        //       type: 'html',
        //       content: `<p>La désinformation et la fabrication d’images falsifiées dont l’IA a accru la faisabilité, constituent une menace sérieuse pour nos démocraties. Notre capacité à détecter les fausses images ou les vidéos falsifiées, à comprendre comment elles se propagent et à analyser leur impact sur nos sociétés, représentent un enjeu important. Le FIP apporte son financement à des travaux de recherches scientifiques universitaires consacrés à la lutte contre la désinformation et la propagation de fausses images ou vidéos.</p>
        //       <p> <strong>Quand présenter un projet ? </strong></p>
        //       <p>La date limite de dépôt des candidatures est fixée à l’occasion de chaque campagne d’appel à projets. A l’issue d’une phase de pré-sélection, les porteurs de projets retenus seront auditionnés par le Conseil d’administration avant la décision finale.</p>
        //       <p> <strong>Quel est le montant de financement apporté par le Fonds?  </strong></p>
        //       <p>Le FIP consacre chaque année XXX euros au financement de cette mission, cette somme pouvant être répartie entre plusieurs projets. Le conseil d’administration a seul compétence pour décider s’il accorde ou non son soutien à un projet et définir le montant qu’il lui apporte. 
        //       Le financement peut intervenir en complément d’autres modes de financement du projet (bourses, subventions…).  
        //       Aucune dépense engagée avant le déblocage des sommes par le FIP ne pourra être couverte par le financement. 
        //       </p>
        //       <p>Pour candidater, le porteur de projet doit remplir ce formulaire en ligne. Il peut fournir jusqu’à dix documents supplémentaires qu’il estime utiles pour enrichir la compréhension du projet, sa nécessité, et son caractère d’intérêt général. </p>
        //       <p>Chaque porteur de projets ne peut soumettre qu’une seule candidature par appel à projets. 
        //       Les informations transmises par les candidats pour justifier de l’intérêt de leur projet de recherche sont strictement confidentielles et ont pour seul but d’évaluer ce projet. Les membres du conseil d’administration s’engagent à conserver ces informations confidentielles à ne pas les partager en dehors du conseil.  
        //       </p>
        //       `
        //     },
        //     eligibility: 'Chercheur ou étudiant chercheur en université. (A préciser)',
        //     nb: 'NB: Le fonds dispose chaque année d’un budget d’environ 200.000  e qu’il répartit entre ses différentes missions et les sollicitations qui lui sont soumises. Le Conseil d’administration a seul compétence pour décider s’il accorde ou non un soutien, ainsi que le cas échéant, le montant de celui-ci.',
        //     fields: {
        //       lastName: {
        //         name: 'lastname',
        //         label: 'Nom',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         rules:[
        //               v => !!v || 'Nom est obligatoire',
        //               v => (v? v.length <= 10 : '') || 'Max 10 caractères',
        //             ]
        //         },
        //       firstName: {
        //         name: 'firstName',
        //         label: 'Prénom',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         },
        //       email: {
        //         name: 'email',
        //         label: 'Email',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         rules: [
        //                 v => !!v || 'E-mail is required',
        //                 v => /.+@.+/.test(v) || 'E-mail must be valid',
        //               ]
        //         },
        //         phone: {
        //         name: 'phone',
        //         label: 'Téléphone',
        //         type: 'String',
        //         lenght: 20,
        //         required: true,
        //         },
        //       rattachement: {
        //         name: 'ratachement',
        //         label: 'Université ou laboratoire de recherche de rattachement',
        //         type: 'String',
        //         counter: 255,
        //       },
        //       description : {
        //         label: 'Description du projet de recherche en 3.000 signes',
        //         type: 'textarea',
        //         lenght: 3000,
        //         counter: 3000,
        //         name: 'autre'
        //       },
        //       fichier: {
        //         intro : 'Document attestant que le projet de recherche a reçu l’aval de l’université et ou du laboratoire de recherche',
        //         label: 'Document attestant que le projet de recherche a reçu l’aval de l’université et ou du laboratoire de recherche',
        //         type: 'file',
        //         multi: true,
        //         name: 'files',
        //         'max-files':10,
        //         ref:"pond",
        //         'label-idle':"Glisser-déplacer ou cliquer pour téléchager des documents",
        //         'allow-multiple': "true",
        //         'accepted-file-types': "image/jpeg, image/png, application/pdf",
        //         server: "https://fondindependancepresse.ew.r.appspot.com/api",
        //         instantUpload: "true",
        //         labelFileProcessing: "Téléchargement...",
        //         labelFileProcessingComplete: "Téléchargement terminé",
        //         labelTapToCancel: "Cliquer pour annuler",
        //         labelTapToRetry: "Cliquer pour réessayer",
        //         labelTapToUndo: "Cliquer pour annuler",
        //       },
        //       montant: {
        //         name: "montant",
        //         label: 'Précisez le montant du soutien sollicité et le type de dépenses qu’il financera.',
        //         type: 'String',
        //         counter: 255,
        //         multiline: ''
        //       },
        //       benef: {
        //         name: "benef",
        //         label: 'Bénéficiez-vous d’une autre source de financement ou avez-vous sollicité d’autres sources de financement? ',
        //         type: 'String',
        //         counter: 255,
        //       },
        //       rgpd: {
        //         type: 'html',
        //         content: `En remplissant ce formulaire, vous consentez à ce que le Fonds pour l’Indépendance de la Presse, responsable de traitement, utilise les données personnelles vous concernant pour le traitement de votre demande de financement. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de modification, de portabilité, de suppression et d’opposition au traitement des informations vous concernant. Vous pouvez exercer ces droits via ce formulaire de contact <a href='/contact-us'> ici </a>. Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr).`,
        //       },
        //       cgu: {
        //         type: 'checkbox',
        //         label: `Accepter la politique de traitement des données personnelles`,
        //         required: true,
        //         name: "cgu",
        //         rules : [v => !!v || 'CGU obligatoire',]
        //       },
        //     }
        //   },
        //   {
        //     id: 3,
        //     title: 'Vous sollicitez un soutien financier dans le cadre d’une procédure judiciaire susceptible de menacer la liberté de la presse',
        //     description: {
        //       type: 'html',
        //       content: `<p>Le FIP a vocation à apporter son soutien à des médias indépendants qui font l’objet de procédures judiciaires longues ou multiples, dont le caractère coûteux et chronophage peut avoir pour effet de les dissuader de traiter certains sujets d'information. C’est notamment le cas des procédures-bâillon contre lesquelles l’Union européenne a adopté une directive au printemps dernier. Ces attaques répétées contre les médias sapent les libertés fondamentales que sont la liberté d’expression et la liberté d’information. 
        //               L’attribution de cette aide financière et son montant relèvent de la seule décision du conseil d’administration du FIP. Elle est réservée aux journalistes professionnels indépendants ainsi qu’aux médias indépendants dont l’existence même est fragilisée par les conséquences de ces  poursuites judiciaires.
        //             </p>`
        //     },
        //     eligibility: 'Soutien réservé aux journalistes professionnels indépendants ou médias indépendants',
        //     nb: 'NB: Le fonds dispose chaque année d’un budget d’environ 200.000  e qu’il répartit entre ses différentes missions et les sollicitations qui lui sont soumises. Le Conseil d’administration a seul compétence pour décider s’il accorde ou non un soutien, ainsi que le cas échéant, le montant de celui-ci.',
        //     fields: {
        //       journaliste : {
        //         type: 'radio',
        //         orientation: 'row',
        //         label: "Type de structure :",
        //         required: true,
        //         options : [
        //           {
        //             name : 'journaliste', 
        //             label : 'Journaliste'
        //           }, 
        //           {
        //             name : 'editeur', 
        //             label : 'Editeur'
        //           },
        //           {
        //             name : 'asso', 
        //             label : 'Association'
        //           }
        //         ],
        //         name: 'journaliste',
        //         default:'journaliste',
        //       },
        //       lastName: {
        //         name: 'lastname',
        //         label: 'Nom',
        //         type: 'String',
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'journaliste',
        //         },
        //         lenght: 250,
        //         required: true,
        //         rules:[
        //               v => !!v || 'Nom est obligatoire',
        //               v => (v? v.length <= 10 : '') || 'Max 10 caractères',
        //             ]
        //         },
        //       firstName: {
        //         name: 'firstName',
        //         label: 'Prénom',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'journaliste',
        //         },
        //         },

        //       denomination: {
        //         name: "denominiation",
        //         label: 'Dénomination sociale de la structure',
        //         type: 'String',
        //         counter: 255,
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },
        //       creationDate: {
        //         name: "creationdate",
        //         label: 'Date de création',
        //         type: 'String',
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },
        //       formSociale : {
        //         label: 'Forme sociale',
        //         type: 'String',
        //         counter: 255,
        //         name: 'formesociale',
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },

        //       representantLegal : {
        //         label: 'Représentant légal',
        //         type: 'String',
        //         counter: 255,
        //         name: 'representantlegal',
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },
        //       address: {
        //         name: 'address',
        //         label: 'Adresse',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         },
        //       email: {
        //         name: 'email',
        //         label: 'Email',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         rules: [
        //                 v => !!v || 'E-mail is required',
        //                 v => /.+@.+/.test(v) || 'E-mail must be valid',
        //               ]
        //         },
        //         phone: {
        //         name: 'phone',
        //         label: 'Téléphone',
        //         type: 'String',
        //         counter: 13,
        //         required: true,
        //         },
        //       support: {
        //         name: 'support',
        //         label: 'Sur quel supports vous exprimez-vous?',
        //         type: 'String',
        //         counter: 255,
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'journaliste',
        //         },
        //       },
        //       titrepublication: {
        //         name: 'titrepublication',
        //         label: 'Quel est le titre ou l’adresse url de votre publication?',
        //         type: 'String',
        //         counter: 255,
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },
        //       cppap: {
        //         name: 'cppap',
        //         label: 'Numéro de certificat d’inscription délivré par la CPPAP?',
        //         type: 'String',
        //         counter: 255,
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },
        //       actionnariat: {
        //         name: 'actionnariat',
        //         label: 'Actionnariat : montant du capital social, liste (avec pourcentages) des actionnaires supérieurs à 5%',
        //         type: 'textarea',
        //         counter: 255,
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'editeur',
        //         },
        //       },
        //       revenueasso : {
        //         name: 'revenueasso',
        //         label: 'Préciser les sources de revenus (dons, subventions, activités commerciales)',
        //         type: 'textarea',
        //         counter: 255,
        //         displayIf:{
        //           fieldData: 'journaliste',
        //           value: 'asso',
        //         },
        //       },
        //       typdedocasso : {
        //         type: 'content',
        //         text: 'Joindre l dernier bilan comptable',
        //         displayIf : {
        //           fieldData: 'journaliste',
        //           value : 'asso'
        //         }
        //       },
        //       descrption: {
        //         label: "Préciser la procédure judiciaire dont vous faites l’objet en 3000 signes",
        //         type:"textarea",
        //         counter: 3000,
        //         name: 'descriptionprojet',
        //         clearable: true
        //       },
        //       montant: {
        //         label: "Précisez le montant du soutien sollicité et, s’il est accordé, le type de dépenses qu’il financera",
        //         type:"String",
        //         counter: 255,
        //         name: 'montant',
        //       },
        //       autrefinance : {
        //         label: "Bénéficiez-vous d’une autre source de financement ou avez-vous sollicité une autre source de financement",
        //         name: 'autrefinance',
        //         counter: 255,
        //         type: 'String',
        //       },
        //       typdedocEditeru : {
        //         type: 'content',
        //         text: 'Joindre un extrait K Bis et le dernier bilan comptable',
        //         displayIf : {
        //           fieldData: 'journaliste',
        //           value : 'editeur'
        //         }
        //       },
        //       fichier: {
        //         intro: 'Joindre un extrait K Bis et le dernier bilan comptable',
        //         label: 'Justificatifs',
        //         type: 'file',
        //         multi: true,
        //         name: 'files',
        //         'max-files':5,
        //         ref:"pond",
        //         'label-idle':"Glisser-déplacer ou cliquer pour téléchager des documents",
        //         'allow-multiple': "true",
        //         'accepted-file-types': "image/jpeg, image/png, application/pdf",
        //         server: "https://fondindependancepresse.ew.r.appspot.com/api",
        //         instantUpload: "true",
        //         labelFileProcessing: "Téléchargement...",
        //         labelFileProcessingComplete: "Téléchargement terminé",
        //         labelTapToCancel: "Cliquer pour annuler",
        //         labelTapToRetry: "Cliquer pour réessayer",
        //         labelTapToUndo: "Cliquer pour annuler",
        //       },
        //       rgpd: {
        //         type: 'html',
        //         content: `En remplissant ce formulaire, vous consentez à ce que le Fonds pour l’Indépendance de la Presse, responsable de traitement, utilise les données personnelles vous concernant pour le traitement de votre demande de financement. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de modification, de portabilité, de suppression et d’opposition au traitement des informations vous concernant. Vous pouvez exercer ces droits via ce formulaire de contact <a href='/contact-us'> ici </a>. Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr).`,
        //       },
        //       cgu: {
        //         type: 'checkbox',
        //         label: `Accepter la politique de traitement des données personnelles`,
        //         required: true,
        //         name: "cgu",
        //         rules : [v => !!v || 'CGU obligatoire',]
        //       },
        //     }
        //   },
        //   {
        //     id: 4,
        //     title: 'Vous sollicitez un accompagnement dans une action d’éducation aux médias',
        //     description: {
        //       type: 'html',
        //       content: ` Le FIP accompagne les initiatives dédiées à une meilleure connaissance du journalisme et de l’information indépendante auprès des jeunes publics. L’éducation aux médias est en effet  un moyen important pour lutter contre la désinformation et la propagation de fausses nouvelles.
        //       Ces actions d’éducation aux médias sont ouvertes à toutes et tous. Elles peuvent se dérouler en temps scolaire en lien avec les enseignants mais s’adressent également à des jeunes en situation spécifique (Institut médico-éducatif, protection judiciaire de la jeunesse, établissements de soins, écoles de la deuxième chance, établissements pour élèves en difficulté scolaire…). Elles peuvent également avoir pour cadre des projets de l’éducation populaire pour toucher des jeunes hors du temps scolaire. 
        //       `
        //     },
            
        //     nb: 'NB: Le Conseil d’administration du Fonds a seul compétence pour décider des projets d\'éducation aux médias qu’il accompagne chaque année. ',
        //     fields: {
        //       denomination: {
        //         name: "denominiation",
        //         label: 'Dénomination de l\'Établissement',
        //         type: 'String',
        //         counter: 255,
        //       },
        //       representantLegal : {
        //         label: 'Représentant légal',
        //         type: 'String',
        //         counter: 255,
        //         name: 'representantlegal',
        //       },
        //       nomEnseignant : {
        //         label: 'Nom de l\'enseignant',
        //         type: 'String',
        //         counter: 255,
        //         name: 'nomenseignant',
        //       },
        //       address: {
        //         name: 'address',
        //         label: 'Adresse',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         },
        //       email: {
        //         name: 'email',
        //         label: 'Email',
        //         type: 'String',
        //         lenght: 250,
        //         required: true,
        //         rules: [
        //                 v => !!v || 'E-mail is required',
        //                 v => /.+@.+/.test(v) || 'E-mail must be valid',
        //               ]
        //         },
        //         phone: {
        //         name: 'phone',
        //         label: 'Téléphone',
        //         type: 'String',
        //         counter: 13,
        //         required: true,
        //         },
        //       descrption: {
        //         label: "Descriptif du projet et type de soutien sollicité (intervention directe d’un journaliste, accompagnement d’un projet éditorial, visite d’un journal, autres…)  en 3000 signes",
        //         type:"textarea",
        //         counter: 3000,
        //         name: 'descriptionprojet'
        //       },
        //       montant: {
        //         label: "Précisez le montant du soutien sollicité et, s’il est accordé, le type de dépenses qu’il financera",
        //         type:"String",
        //         counter: 255,
        //         name: 'montant',
        //       },
        //       fichier: {
        //         label: 'Justificatifs',
        //         type: 'file',
        //         multi: true,
        //         name: 'files',
        //         'max-files':5,
        //         ref:"pond",
        //         'label-idle':"Glisser-déplacer ou cliquer pour téléchager des documents",
        //         'allow-multiple': "true",
        //         'accepted-file-types': "image/jpeg, image/png, application/pdf",
        //         server: "https://fondindependancepresse.ew.r.appspot.com/api",
        //         instantUpload: "true",
        //         labelFileProcessing: "Téléchargement...",
        //         labelFileProcessingComplete: "Téléchargement terminé",
        //         labelTapToCancel: "Cliquer pour annuler",
        //         labelTapToRetry: "Cliquer pour réessayer",
        //         labelTapToUndo: "Cliquer pour annuler",
        //       },
        //       rgpd: {
        //         type: 'html',
        //         content: `En remplissant ce formulaire, vous consentez à ce que le Fonds pour l’Indépendance de la Presse, responsable de traitement, utilise les données personnelles vous concernant pour le traitement de votre demande de financement. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de modification, de portabilité, de suppression et d’opposition au traitement des informations vous concernant. Vous pouvez exercer ces droits via ce formulaire de contact <a href='/contact-us'> ici </a>. Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr).`,
        //       },
        //       cgu: {
        //         type: 'checkbox',
        //         label: `Accepter la politique de traitement des données personnelles`,
        //         required: true,
        //         name: "cgu",
        //         rules : [v => !!v || 'CGU obligatoire',]
        //       },
        //     }
        //   },
        // ],
        valid: false,
        formData: {},
        formComplete: false,
      }
    },
    computed: {
      current_post() {
        console.log('curpost', this.mypost)
        return this.mypost
      },
    },
    methods: {
      sendFormData(){
        if (this.$refs.form.validate()){
         this.disableForm = true
        const {files, ...formData} = this.formData
        const myfiles = []
        this.formData.files.forEach(element => {
          myfiles.push({serverId : element.serverId, type : element.source.type, name: element.source.name, size: element.source.size})
        });
        // formData.files = null
        axios.post(this.current_post.serverPostUrl, {
          data : {
            fields : formData, 
            files : myfiles, 
            customerId: this.current_post.customerId || 'fip', 
            operationId: this.current_post.operationId || 'fip',
            formId: this.current_post._id || 'unknown',
          }})
          .then((response)=>{
            if (response.status >= 200 && response.status <= 300 ){
              this.message = "Votre demande a bien été soumise!"
              this.snackbar = true
              this.disableForm = true
              this.formComplete = true
            }else{
              this.message = "Une erreur est survenue! \n Veuillez réessayer ultérieurement"
              this.snackbar = true
              console.error(response)
              this.disableForm = false
            }
          })
          .catch((err)=> console.log(err))
            this.disableForm = false
            console.log("formData",this.formData)
        }
        
      },
      handleFilePondInit: function () {
      
    },
    },
    watch: {
      formData: {
        handler(newValue, oldValue) {
          // Remarque: `newValue` sera égale à `oldValue` ici
          // à chaque mutation imbriquée tant que l'objet lui-même
          // n'a pas été remplacé.
          // console.log("new Value", newValue)
          // console.log("oldValue ", oldValue)
        },
        deep: true
      }
  },
  async mounted (){
    const response = await axios.get(`${process.env.VUE_APP_FORM_URL}/${this.$route.query.id}`)
        console.log(response)
        if (response && response.status == 200){
          this.mypost = response.data.form
        }else{
          this.mypost = null
        }

        console.log("curr_post", this.mypost)
  }
  }
</script>
